import {closeModal,
  setBusy,
  setNetworkStatus,
  setOpenModal,
  setSettings,
  setAppVersionNumber,
} from './action-types'
import { get } from '../utils/api/api'
import {dlSettings} from "../utils/api/app";
import {appVersionNumber} from '../constants/constants'

export function setBusyIndicator(busy){
  return dispatch => {
    dispatch(setBusy(busy))
  }
}

export const getAppVersion = () => {
  return async (dispatch) => {
    dispatch(setAppVersionNumber(appVersionNumber))
  }
}

export const updateNetworkStatus = (status) => {
  return async (dispatch) =>  {

    if(status === 'OFFLINE' || status === 'SLOW'){
      dispatch(setNetworkStatus(status))
      return
    }

    let test = await get("/user/login_status")
    const testSuccessful = (parseInt(test) === 1 || parseInt(test) === 0)

    testSuccessful ? dispatch(setNetworkStatus('ONLINE')) : dispatch(setNetworkStatus('SLOW'))

    return testSuccessful
  }
}

export const openModal = (modal, modalData) => {
  return (dispatch) => {
    dispatch(setOpenModal(modal, modalData))
  }
}

export const dismissModal = () => {
  return (dispatch) => {
    dispatch(closeModal())
  }
}

export const getSchoolSettings = (schoolSelected) => {
  return async (dispatch) => {
    if (!schoolSelected){
      dispatch(setSettings(null))
    }
    else{
      const settings = await dlSettings(dispatch)
      dispatch(setSettings(settings))
    }
  }
}


