import {connect} from 'react-redux'
import {Dependants} from './dependants'
import {getDependants, selectDependant} from "../actions";
import {dependants} from "../selectors";

const mapStateToProps = (state) => ({
  dependants: dependants(state)
})

const mapDispatchToProps = (dispatch) => ({
  getDependants: () => dispatch(getDependants()),
  selectDependant: (dependantId) => dispatch(selectDependant(dependantId))
})

export const DependantsConnected = connect(mapStateToProps, mapDispatchToProps)(Dependants)