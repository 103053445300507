import {
  SET_BUSY,
  SET_NETWORK_STATUS,
  SET_SCHOOL_SELECTED,
  SET_VALID_LOGIN_TOKEN,
  SET_APP_INITIALISED,
  SET_OPEN_MODAL,
  CLOSE_MODAL,
  SET_SETTINGS,
  SET_SCHOOL_DETAILS,
  SET_COLOUR_MODE,
  SET_APP_VERSION_NUMBER,
} from './action-types'

const initialState = {
  networkStatus: 'ONLINE',
  schoolSelected: null,
  validLoginToken: null,
  modals: [],
  colourMode: 'light'
}

export function appReducer(state = initialState, action){
  switch (action.type) {

    case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      }

    case SET_APP_VERSION_NUMBER:
      return {
        ...state,
        appVersionNumber: action.payload
      }

    case SET_SCHOOL_DETAILS:
      return {
        ...state,
        schoolDetails: action.payload
      }

    case SET_BUSY:
      return { ...state,
        appBusy: action.payload
      }

    case SET_NETWORK_STATUS:
      return {
        ...state,
        networkStatus: action.payload
      }

    case SET_SCHOOL_SELECTED:
      return {
        ...state,
        schoolSelected: action.payload
      }

    case SET_VALID_LOGIN_TOKEN:
      return {
        ...state,
        validLoginToken: action.payload
      }

    case SET_OPEN_MODAL:
      return {
        ...state,
        modals: state.modals.concat({modalName: action.payload.modal, modalData: action.payload.modalData})
      }

    case CLOSE_MODAL:
      return {
        ...state,
        modals: state?.modals?.length > 1 ? state.modals.slice(0, state.modals.length - 1) : []
      }

    case SET_COLOUR_MODE:
      return {
        ...state,
        colourMode: action.payload
      }

    default:
      // alert(JSON.stringify(state))
      return state
  }
}