import { createStore, applyMiddleware, combineReducers  } from 'redux'
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension'
import {appReducer} from "./app";
import userManagementReducer from './user-management/reducer'
import {pageReducer, contentReducer, eventLogReducer} from './pages'

const rootReducer = combineReducers({
  app: appReducer,
  userManagement: userManagementReducer,
  page: pageReducer,
  content: contentReducer,
  eventLog: eventLogReducer,
})

const initialState = (localStorage.getItem('isLoggedIn') === null) ? {} : {userManagement: { isLoggedIn: true }}

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
)

export default store