export const CHECK_LOGGEDIN_BEGIN   = 'CHECK_LOGGEDIN_BEGIN'
export const SET_LOGGEDIN = 'SET_LOGGEDIN'
export const CHECK_LOGGEDIN_FAILURE = 'CHECK_LOGGEDIN_FAILURE'
export const SET_LOGIN_DETAILS = 'SET_LOGIN_DETAILS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const SET_LOGIN_MESSAGE = 'SET_LOGIN_MESSAGE'
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const RESET_APP = 'RESET_APP'
export const SET_DEPENDANTS = 'SET_DEPENDANTS'
export const SET_SELECTED_DEPENDANT = 'SET_SELECTED_DEPENDANT'
export const SET_ROLES = 'SET_ROLES'