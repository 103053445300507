import {DefaultTemplate} from "../../components";
import React, {useEffect} from "react";
import {IonAvatar, IonButton} from "@ionic/react";
import styles from './styles.module.scss'
import {profilePic} from '../../icons'

export const UserAccount = ({ userDetails, logout }) => {

  return (
      <DefaultTemplate>

        <div className={styles.pic_container}>
          <div className={styles.avatar_container}>
            <IonAvatar className={styles.avatar}>
              <img src={profilePic} />
            </IonAvatar>
          </div>
        </div>

        <div className={styles.account_details}>
          <h1>{userDetails?.display_name}</h1>
        </div>

        <div className={styles.button_container}>
          <IonButton expand="block" onClick={() => { alert('Not yet implemented') }}>Change Password</IonButton>
          <IonButton expand="block" onClick={() => { logout() }}>Log out</IonButton>
        </div>

      </DefaultTemplate>
  );
}
