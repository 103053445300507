import {connect} from 'react-redux'
import {DefaultTemplate} from "./page";
import {appName} from "../../../app/selectors";
import {getSubMenu, subMenu} from '../../../pages'

const mapStateToProps = (state) => ({
  appTitle: appName(state),
  subMenu: subMenu(state)
})

const mapDispatchToProps = (dispatch) => ({
})

export const DefaultTemplateConnected = connect(mapStateToProps, null)(DefaultTemplate)