import React, {useEffect, useMemo, useState} from "react";
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from "@capacitor/core";
import { syncDeviceToken } from './logic'
import {ALERT_ITEM} from "../../constants/modals";
import {AlertItem} from "../../pages/alert-list/modals/alert-item";

export const PushNotificationHandler = ({
                                          userId,
                                          getAlerts,
                                          openModal,
                                          logEvent,
                                        }) => {

  const [registering, setRegistering] = useState(false)

  const platform = useMemo(() => {
    return Capacitor.getPlatform()
  }, [Capacitor.getPlatform()])

  useEffect(async () =>  {
    if (!userId){
      return
    }

    logEvent(`Initialising (${platform})`)

    if(Capacitor.getPlatform() === 'web'){
      await syncDeviceToken(userId, 'web_test_5', 'web', logEvent)

    }
    else{
      PushNotifications.requestPermissions().then(
        (result) => {
          logEvent(`Permission Granted (${platform}): ${result.granted}`)

          if (result.granted) {
            logEvent(`Registering (${platform})`)
            PushNotifications.register()
          }
        }
      )

      PushNotifications.addListener('registration',
        async (token) => {
          logEvent(`Token received (${platform})`)

          if (!registering && userId){
            setRegistering(true)
            await syncDeviceToken(userId, token.value, Capacitor.getPlatform(), logEvent)
            setRegistering(false)
          }
        }
      )

      PushNotifications.addListener('registrationError',
        (error) => {
          logEvent(`Registration error (${platform}): ${JSON.stringify(error)}`)
          alert('Error on registration: ' + JSON.stringify(error));
        }
      )

      PushNotifications.addListener('pushNotificationReceived',
        (notification) => {
          logEvent(`Notification received (${platform})`)
        }
      )

      PushNotifications.addListener('pushNotificationActionPerformed',
        async (notification) => {

          const alerts = await getAlerts()
          const alrt = alerts?.find(a => parseInt(a.alert_id) === parseInt(notification.notification.data?.alert_id))

          openModal(ALERT_ITEM, alrt)
        }
      )

      logEvent(`Registering (${platform})`)
      PushNotifications.register()
    }

  }, [userId])

  return (
    <>
      <AlertItem />
    </>
  )
}