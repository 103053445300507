import {connect} from 'react-redux'
import {Access} from './access'
import {checkAccessCode } from '../actions'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  checkAccessCode: (status) => dispatch(checkAccessCode(status))
})

export const AccessConnected = connect(null, mapDispatchToProps)(Access)