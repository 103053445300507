import {DefaultTemplate, PageMenu} from '../../components'
import {useEffect} from "react";
import {useParams} from 'react-router-dom'
import styles from './styles.module.scss'

export const Page = ({getPageContent,
                       pageContent}) => {

  let {nid} = useParams()

  useEffect(()=>{
    getPageContent(nid)
  }, [nid])

  return (
    <DefaultTemplate refreshFunction={() => {getPageContent(nid)}} subMenuId={nid}>
      <div className={styles.page_container}>
        <h1>{pageContent?.title}</h1>
        <div dangerouslySetInnerHTML={{__html: pageContent?.body}}></div>
      </div>

    </DefaultTemplate>
  )
}