import {connect} from 'react-redux'
import {App} from './app'
import { startUp } from '../logic'
import {appBusy, schoolSelected, validLoginToken, isOnline} from "../selectors";
import { userDetails } from "user-management"
import { getSchoolSettings } from "../actions";
import {downloadUserDetails} from "../../user-management";
import {setColourMode} from "../action-types";

const mapStateToProps = (state) => ({
  appBusy: appBusy(state),
  schoolSelected: schoolSelected(state),
  validLoginToken: validLoginToken(state),
  isOnline: isOnline(state),
  userDetails: userDetails(state),
})

const mapDispatchToProps = (dispatch) => ({
  startUp: () => startUp(dispatch),
  getSettings: (schoolSelected) => dispatch(getSchoolSettings(schoolSelected)),
  getUserDetails: () => dispatch(downloadUserDetails()),
  setColourMode: (mode) => dispatch(setColourMode(mode))
})

export const AppConnected = connect(mapStateToProps, mapDispatchToProps)(App)