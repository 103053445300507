import React, { useEffect, useMemo, useState } from 'react'
import { DefaultTemplate, NoContent } from '../../components'
import { Eventcalendar, getJson } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';

export const GoogleCalendar = ({
                                 match,
                                 colourMode,
                                 menuItems,
                                 showBusy,
                          }) => {

  const [events, setEvents] = useState([])
  const [calendarSource, setCalendarSource] = useState('')

  useEffect(() => {
    const calendarUrl = menuItems.find(m => m.nid == match.params.id)?.data
    setCalendarSource(calendarUrl)
  }, [menuItems])

  useEffect(() => {
    if (calendarSource) getEvents()
  }, [calendarSource])

  useEffect(() =>{
    if (events?.items){
      let event;
      let eventList = [];
      for (let i = 0; i < events.items.length; ++i) {
        event = events.items[i];
        eventList.push({
          start: new Date(event.start?.date),
          end: new Date(event.end?.date),
          title: event.summary || 'No Title'
        });
      }
      setEvents(eventList)
    }
  }, [events])

  const getEvents = () => {
    if (calendarSource) {
      showBusy(true)
      getJson(calendarSource, (events) => {
        setEvents(events)
        showBusy(false)
      }, 'jsonp')
    }
  }

  const view = useMemo(() => {
    return {
      calendar: { type: 'month' },
      agenda: { type: 'day' }
    };
  }, []);

  return (
    <DefaultTemplate refreshFunction={() => {getEvents()}}>
      {
        <Eventcalendar
          theme="material"
          themeVariant={colourMode}
          clickToCreate={false}
          dragToCreate={false}
          dragToMove={false}
          dragToResize={false}
          data={events}
          view={view}
          exclusiveEndDates={true}
        />
      }
    </DefaultTemplate>
  )
}