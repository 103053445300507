import * as actionTypes from './action-types'

export const setLoggedIn = (loggedIn) => ({
  type: actionTypes.SET_LOGGEDIN,
  payload: { loggedIn }
});

export const clearUserData = () => ({
  type: actionTypes.CLEAR_USER_DATA,
});

export const setUserDetails = (details) => ({
  type: actionTypes.SET_USER_DETAILS,
  payload: details
})

export const setLoginDetails = (user) => ({
  type: actionTypes.SET_LOGIN_DETAILS,
  payload: user
});

export const loginFailure = (message) => ({
  type: actionTypes.LOGIN_FAILURE,
  payload: message
})

export const setLoginMessageSuccess = (message) => ({
  type: actionTypes.SET_LOGIN_MESSAGE,
  payload: message
})

export const setDependants = (dependants) => ({
  type: actionTypes.SET_DEPENDANTS,
  payload: dependants
})

export const setSelectedDependant = (id) => ({
  type: actionTypes.SET_SELECTED_DEPENDANT,
  payload: id
})

export const setRoles = (roles) => ({
  type: actionTypes.SET_ROLES,
  payload: roles
})