import * as actionTypes from './action-types'
import {getCurrentDateTimeString} from '../../../utils/helpers/date'

export const addToEventLog = (eventType, eventDetails, verbosity = 'Normal') => ({
  type: actionTypes.ADD_TO_EVENT_LOG,
  payload: {
    time: getCurrentDateTimeString(),
    type: eventType,
    details: eventDetails,
    verbosity: verbosity
  }
})
