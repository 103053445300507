import {IonModal} from "@ionic/react";
import {ModalTemplate} from "../../../../components";
import {useEffect} from "react";

export const Event = ({isOpen,
                          close,
                          eventId,
                          getEvent,
                          event}) => {

  useEffect(() => {
    eventId && getEvent(eventId)
  }, [getEvent, eventId])

  return (
      <IonModal isOpen={isOpen} backdropDismiss={true} showBackdrop={true}>
        <ModalTemplate title='Event'
                       closeMethod={() => {close()}}>

          <h1>{event?.title}</h1>
          <div dangerouslySetInnerHTML={{__html: event?.body}}></div>

        </ModalTemplate>
      </IonModal>
  )
}