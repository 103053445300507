import {
  IonButton,
  IonModal,
  IonList,
  IonItem
} from "@ionic/react";
import {ModalTemplate} from "../../components";
import React, {useEffect} from "react";

export const Dependants = ({ dependants,
                             getDependants,
                             selectDependant,
                             isOpen,
                             close }) => {

  useEffect(() => {
    if (isOpen) getDependants()
  }, [isOpen])

  const setSelectDependant = (dependantId) => {
    selectDependant(dependantId)
    close()
  }

  return (
    <IonModal isOpen={isOpen}
              backdropDismiss={true}
              showBackdrop={true}>
      <ModalTemplate title='Select Pupil'
                     closeMethod={() => {close()}}>

        <p>Please select...</p>
        <IonList>
          {
            dependants?.map((dep, index) => {
              return (
                <IonItem key={index} onClick={() => setSelectDependant(dep.uid)}>
                  {`${dep.first_name} ${dep.last_name}`}
                </IonItem>
              )
            })
          }
        </IonList>

      </ModalTemplate>

    </IonModal>
  )

}