import React, {useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {Access} from "../../user-management";

export const PrivateRoute = ({path, component: Component, authed}) => {

  return (
    <Route path={path}
           render={(props) =>
             authed
               ?
               <Component {...props} />
               :
               <Redirect from={path} to='/login' exact/>
           }
    />

    // <Route
    //   {...rest}
    //   render={(props) => authed === true
    //     ?
    //     <Component {...props} />
    //     :
    //     schoolSelected
    //     ?
    //       <Redirect to={{pathname: '/login', state: {from: props.location.pathname}}} />
    //       :
    //       <Redirect to={{pathname: '/access', state: {from: props.location.pathname}}} />
    //     }
    // />
  )
};