import {stringify} from "querystring";

export const SET_MENU = 'SET_MENU'
export const SET_SUB_MENU = 'SET_SUB_MENU'
export const SET_ALERTS = 'SET_ALERTS'
export const SET_PAGE_CONTENT = 'SET_PAGE_CONTENT'
export const SET_CONTACT_PAGE_DETAILS = 'SET_CONTACT_PAGE_DETAILS'
export const SET_PDF_LIST = 'SET_PDF_LIST'
export const SET_SELECTED_ALERT_ID = 'SET_SELECTED_ALERT_ID'
export const SET_ALERT_READ = 'MARK_ALERT_AS_READ'

export const setMenu = (menu) =>({
  type: SET_MENU,
  payload: menu
})

export const setSubMenu = (subMenu) =>({
  type: SET_SUB_MENU,
  payload: subMenu
})

export const setPageContent = (content) => ({
  type: SET_PAGE_CONTENT,
  payload: content
})

export const setAlerts = (alerts) => ({
  type: SET_ALERTS,
  payload: alerts
})

export const dispatchBasicAction = (actionType, actionPayload) => ({
  type: actionType,
  payload: actionPayload
})

export const setSelectedAlertId = (selectedAlertId) => ({
  type: SET_SELECTED_ALERT_ID,
  payload: selectedAlertId
})

export const setAlertRead = (alertId) => ({
  type: SET_ALERT_READ,
  payload: alertId
})
