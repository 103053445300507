import {combineReducers} from "redux"
import {pageReducer} from "./reducer"
import {attendanceReducer} from './attendance'

export {Home} from './home'
export {Page} from './page'
export {AlertList} from './alert-list'
export {ArticleList} from './article_list'
export {EventList} from './event-list'
export {ContactUs} from './contact-us'
export {PDFList} from './pdf-list'
export {PhotoGallery} from './photo-gallery'
export {Attendance} from './attendance'
export {GoogleCalendar} from './google-calendar'
export {EventLog} from './event-log'

export {getSubMenu, selectAlert} from './actions'
export {subMenu, selectedAlert} from './selectors'

export {pageReducer} from './reducer'
export {eventLogReducer} from './event-log'

export const contentReducer = combineReducers({
  attendance: attendanceReducer,
})