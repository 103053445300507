export const getCurrentDateTimeString = () => {
  return formatLongDate(new Date());
}

export const formatISODate = (dateString) => {

  let date = new Date(dateString)

  let tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
        let norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };

  return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);

}

export const formatLongDate = (dateString) => {
  if(!dateString) return ''
  let d = new Date(dateString)
  return `${getDayName(d)}, ${d.getDate()} ${getMonth(d, true)}, ${d.getFullYear()} - ${getTwoDigitHour(d)}:${getTwoDigitMinute(d)}`
}

export const formatDate = (dateString) => {
  let d = new Date(dateString)
  return `${d.getDate()}${getDateOrdinal(d)} ${getMonth(d)}`
}

export const getDateOrdinal = (d) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

export const getDateAndOrdinal = (d) => {
  return `${d.getDate()}${getDateOrdinal(d)}`
}

export const getMonth = (d, long) => {
  return long ? monthsLong[d.getMonth()]: monthsShort[d.getMonth()]
}

export const getTwoDigitHour = (d) => {
  let hour = d.getHours()
  return ("0" + hour).slice(-2)
}

export const getTwoDigitMinute = (d) => {
  let minute = d.getMinutes()
  return ("0" + minute).slice(-2)
}

const getDayName = (d) => {
  return days[d.getDay()]
}

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const monthsShort = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
export const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']