import React, {useMemo} from "react";
import {
  IonMenu,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonLabel,
} from "@ionic/react";
import styles from './styles.module.scss'

export const Menu = ({ userDetails, isAdmin, appVersionNumber }) => {

  return (
    <IonMenu side="start" contentId="main">
      <IonContent>
        <IonList>
          <IonMenuToggle auto-hide="false">
            <IonItem button routerLink={'/account'} routerDirection="none" className={styles.user_item}
                     lines="none">
              {/*<IonThumbnail slot="start">*/}
              {/*  <img src={userDetails && userDetails.user_picture}/>*/}
              {/*</IonThumbnail>*/}
              <IonLabel>
                <span className={styles.label_display_name}>{userDetails && userDetails.email}</span>
                <br/>
                <span className={styles.label_email}></span></IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle auto-hide="false">
            <IonItem button routerLink={'/home'} routerDirection="none">
              <IonLabel>Home</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle auto-hide="false">
            <IonItem button routerLink={'/account'} routerDirection="none">
              <IonLabel>My Account</IonLabel>
            </IonItem>
          </IonMenuToggle>

          {
            isAdmin &&
            <IonMenuToggle auto-hide="false">
              <IonItem button routerLink={'/event-log'} routerDirection="none">
                <IonLabel>Event Log</IonLabel>
              </IonItem>
            </IonMenuToggle>
          }

          <IonItem>
            <p>App Version: {appVersionNumber}</p>
          </IonItem>

        </IonList>
      </IonContent>
    </IonMenu>
  );
};