export const SET_EVENT_LIST = 'SET_EVENT_LIST'
export const SET_EVENT = 'SET_EVENT'
export const SET_EVENTS_FOR_LIST_VIEW = 'SET_EVENTS_FOR_LIST_VIEW'

export const setEventList = (events) => ({
  type: SET_EVENT_LIST,
  payload: events
})
export const setSelectedEvent = (event) => ({
  type: SET_EVENT,
  payload: event
})

export const setEventsForListView = (events) => ({
  type: SET_EVENTS_FOR_LIST_VIEW,
  payload: events
})
