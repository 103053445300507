import React, {useEffect} from 'react'
import {IonList, IonItem, IonListHeader, IonLabel, IonText} from '@ionic/react'
import {DefaultTemplate, NoContent} from '../../components'
import {ALERT_ITEM} from "../../constants/modals";
import {AlertItem} from "./modals/alert-item";
import styles from './styles.module.scss'
import {formatISODate} from 'utils/helpers/date.js'
import {formatLongDate} from "../../utils/helpers/date";

export const AlertList = ({getAlerts,
                            alerts,
                            openModal,
                            selectedAlert,
                          }) => {

  useEffect(() => {
    (selectedAlert) ? openModal(ALERT_ITEM, selectedAlert) : getAlerts()
  }, [selectedAlert])

  return (
      <DefaultTemplate refreshFunction={() => {getAlerts()}}>
        {
          alerts?.length
          ?
            <IonList>
              <IonListHeader>Alerts</IonListHeader>
              {
                alerts?.map((alert) => {
                  return (
                    <IonItem
                      key={alert.alert_id}
                      onClick={() => openModal(ALERT_ITEM, alert)}
                      className={alert.read ? styles.read : styles.unread}
                    >
                      <IonLabel className="ion-text-wrap">
                        <h3>{alert?.alert_title}</h3>
                        <p>{formatLongDate(alert?.date)}</p>
                      </IonLabel>
                    </IonItem>
                  )
                })
              }
            </IonList>
          :
            <NoContent text='There are no alerts to view'/>
        }
        <AlertItem />

      </DefaultTemplate>
  )
}