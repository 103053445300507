import {connect} from 'react-redux'
import {Article} from "./article";
import {getOpenModal, dismissModal, getOpenModalData} from '../../../../app'
import {ARTICLE} from "../../../../constants/modals";
import {getArticle} from '../../actions'
import {selectedArticle} from "../../../selectors";

const mapStateToProps = (state) => ({
  isOpen: getOpenModal(state, ARTICLE),
  articleId: getOpenModalData(state),
  article: selectedArticle(state)
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(dismissModal()),
  getArticle: (articleId) => dispatch(getArticle(articleId))
})

export const ArticleConnected = connect(mapStateToProps, mapDispatchToProps)(Article)