import {Redirect, Route} from 'react-router-dom'
import React, {useEffect, useMemo} from 'react'
import { IonApp, IonLoading, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import './styles.scss'
import { NetworkStatus } from '../network-status'
import {Access, Login, UserAccount} from '../../user-management'
import {Home, Page, AlertList, ArticleList, EventList, ContactUs, PDFList, PhotoGallery, Attendance, GoogleCalendar, EventLog} from '../../pages'
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { PushNotificationHandler } from '../push-notification-handler'
import { getStartPage } from '../logic'
import {Menu} from "../../components";
import { PrivateRoute } from "../routes";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';

const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix" )
    .map(icon => Icons[icon])

library.add(...iconList)

export const App = ({ startUp,
                      appBusy,
                      schoolSelected,
                      validLoginToken,
                      getSettings,
                      getUserDetails,
                      isOnline,
                      userDetails,
                      setColourMode}) => {

  useEffect(() =>{
    startUp()
  }, [startUp])

  useEffect(() => {
    getSettings(schoolSelected)
  }, [schoolSelected])

  useEffect(() => {
    if (validLoginToken) getUserDetails()
  }, [validLoginToken])

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => setColourMode(e.matches ? 'dark' : 'light'));

    // Setup dark/light mode for the first time
    setColourMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

    // Remove listener
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
      });
    }
  }, [setColourMode]);

  const startPage = useMemo(() => {
    return getStartPage(schoolSelected, validLoginToken)
  }, [schoolSelected, validLoginToken])

  return (
    <IonApp>
      <NetworkStatus />
      <IonLoading isOpen={appBusy} />

      {
        validLoginToken === null || schoolSelected === null
          ?
          <></>
          :
          <IonReactRouter>
            <Menu userDetails={userDetails}/>
            <PushNotificationHandler/>

            <IonRouterOutlet id="main">
              <PrivateRoute authed={validLoginToken}
                            path='/account'
                            component={UserAccount}/>
              <PrivateRoute authed={validLoginToken}
                            path='/home' component={Home}/>
              <PrivateRoute authed={validLoginToken}
                            path='/page/:nid'
                            component={Page}/>
              <PrivateRoute authed={validLoginToken}
                            path='/alert_list'
                            component={AlertList}/>
              <PrivateRoute authed={validLoginToken}
                            path='/article_list/:id'
                            component={ArticleList}/>
              <PrivateRoute authed={validLoginToken}
                            path='/calendar/:id'
                            component={EventList}/>
              <PrivateRoute authed={validLoginToken}
                            path='/contact_page/:id'
                            component={ContactUs}/>
              <PrivateRoute authed={validLoginToken}
                            path='/pdf_list/:id'
                            component={PDFList}/>
              <PrivateRoute authed={validLoginToken}
                            path='/photo_gallery'
                            component={PhotoGallery}/>
              <PrivateRoute authed={validLoginToken}
                            path='/attendance_link'
                            component={Attendance}/>
              <PrivateRoute authed={validLoginToken}
                            path='/google_calendar/:id'
                            component={GoogleCalendar}/>
              <PrivateRoute authed={validLoginToken}
                            path='/event-log'
                            component={EventLog}/>

              <Route path='/access'
                     render={() =>
                       schoolSelected
                         ?
                         <Redirect from="/access" to={startPage} exact/>
                         :
                         <Access/>
                     }
              />

              <Route path='/login'
                     render={() =>
                       validLoginToken
                         ?
                         <Redirect from="/login" to={startPage} exact/>
                         :
                         schoolSelected ? <Login/> : <Redirect from="/login" to='/access' exact/>
                     }
              />

              <Redirect from="/" to={startPage} exact/>

            </IonRouterOutlet>
          </IonReactRouter>
      }
    </IonApp>
  )
}

