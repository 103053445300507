import { get, post, validToken } from './api'

export const isLoggedIn = () => {

  return get("/user/login_status").then(
    (response) => {
      if(response != null){
        return (response == 1)
      }
    }
  )
}

export const tokenActive = async () => {

  const currentToken = localStorage.getItem('jwt')
  if(!currentToken){
    return false
  }

  let token = await validToken()

  return Boolean(token)
}

export const login = async (username, password, dispatch) => {

  let response = await post("/user/login",  { "name" : username, "pass" : password }, null, dispatch)

  if(response.current_user){
    localStorage.setItem('isLoggedIn', 'true')
    localStorage.setItem('loginDetails', JSON.stringify(response))
    localStorage.setItem('jwt', response.access_token)
  }

  return {
    success: Boolean(response.current_user),
    data: response
  }
}

export const logout = async () => {

  const loginDetails = JSON.parse(localStorage.getItem('loginDetails'))
  await post("/user/logout", null, `token=${loginDetails.csrf_token}`)

  localStorage.removeItem('jwt')
  localStorage.removeItem('loginDetails')
  localStorage.removeItem('isLoggedIn')

  return true
}

export const downloadDetails = async (dispatch) => {

  const loginDetails = JSON.parse(localStorage.getItem('loginDetails'))

  if (!loginDetails || !loginDetails?.current_user){
    return null
  }

  const uid = loginDetails?.current_user.uid

  let userDetails = await get(`/user/${uid}`, null, dispatch)

  if(!userDetails){
    // alert("Failed to download user details")
    return null
  }

  if(userDetails.message){
    alert(userDetails.message)
    return null
  }

  return {
    id: userDetails.uid[0].value,
    email: userDetails.mail[0].value,
    firstName: userDetails?.field_first_name[0]?.value,
    lastName: userDetails?.field_last_name[0]?.value,
    externalId: userDetails?.field_external_id[0]?.value,
  }
}

export const dlUserRoles = async (dispatch) => {
  return await get('/user_roles', null, dispatch)
}

export const dlDependants = async (dispatch) => {
  const dependants = await get('/viewsjson/dependants', null, dispatch)

  if (!dependants){
    return []
  }

  return dependants

  // return [
  //   {
  //     uid: 1234,
  //     name: 'Johnny James'
  //   },
  //   {
  //     uid: 5678,
  //     name: 'Jenny James'
  //   }
  // ]
}