import {
  SET_ATTENDANCE
} from './action-types'

export function attendanceReducer(state = {}, action){
  switch (action.type) {
    case SET_ATTENDANCE:
      return {
        ...state,
        attendanceData: action.payload
      }

    default:
      return state
  }

}