import {connect} from 'react-redux'
import {Home} from './home'
import {menu, schoolDetails} from "../selectors";
import {getMenu} from "../actions";
import {coverPicture, coverPictureDark} from '../../app'

const mapStateToProps = (state) => ({
  menu: menu(state),
  coverPicture: coverPicture(state),
  coverPictureDark: coverPictureDark(state),
  schoolDetails: schoolDetails(state)
})

const mapDispatchToProps = (dispatch) => ({
  getMenu: () => dispatch(getMenu()),
})

export const HomeConnected = connect(mapStateToProps, mapDispatchToProps)(Home)