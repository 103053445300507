import React, {useEffect, useState} from 'react'
import {IonList, IonItem, IonListHeader, IonLabel, IonAvatar} from '@ionic/react'
import {DefaultTemplate} from '../../components'
import styles from './styles.module.scss'
import {faAt, faMobileAlt, faWifi} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ContactUs = ({
                            match,
                            getContactDetails,
                            contactDetails,
                          }) => {

  const id = useState(match.params.id)

  useEffect(() => {
    getContactDetails(match.params.id)
  }, [getContactDetails])

  return (
      <DefaultTemplate refreshFunction={() => {getContactDetails(match.params.id)}}>

        <IonList lines="full">
          {
            contactDetails?.filter((c) => { return c.type === 'telephone'}).map((item) => {
              return (
                <a href={'tel:' + item.tel} className={styles.link}>
                 <IonItem className={styles.contact_item}>
                   <IonAvatar slot="start">
                     <FontAwesomeIcon icon={faMobileAlt} />
                   </IonAvatar>

                   <div className={styles.contact_details}>
                     <IonLabel className={styles.title}>{item.title}</IonLabel>
                     <IonLabel>{item.tel}</IonLabel>
                   </div>
                 </IonItem>
                </a>
              )
            })
          }
          {
            contactDetails?.filter((c) => { return c.type === 'email'}).map((item) => {
              return (
                <a href={'mailto:' + item.email} className={styles.link}>
                  <IonItem className={styles.contact_item}>
                    <IonAvatar slot="start">
                      <FontAwesomeIcon icon={faAt} />
                    </IonAvatar>

                    <div className={styles.contact_details}>
                      <IonLabel className={styles.title}>{item.title}</IonLabel>
                      <IonLabel>{item.email}</IonLabel>
                    </div>
                  </IonItem>
                </a>
              )
            }
            )
          }
          {
            contactDetails?.filter((c) => { return c.type === 'url'}).map((item) => {
              return (
                <a href={item.url} className={styles.link}>
                  <IonItem className={styles.contact_item}>
                    <IonAvatar slot="start">
                      <FontAwesomeIcon icon={faWifi} />
                    </IonAvatar>

                    <div className={styles.contact_details}>
                      <IonLabel className={styles.title}>{item.title}</IonLabel>
                      <IonLabel>{item.url}</IonLabel>
                    </div>
                  </IonItem>
                </a>
              )
            }
            )
          }
        </IonList>

      </DefaultTemplate>
  )
}