import React, {useEffect} from 'react'
import {IonList, IonItem, IonListHeader, IonLabel} from '@ionic/react'
import {DefaultTemplate, NoContent} from '../../components'
import styles from './styles.module.scss'
import {placeholderImage} from './placeholder'
import {ARTICLE} from "../../constants/modals";
import {Article} from './modals'

export const ArticleList = ({
                              match,
                              getArticleList,
                              articles,
                              openModal
                            }) => {



  useEffect(() => {
    getArticleList(match.params.id)
  }, [getArticleList])

  return (
      <DefaultTemplate refreshFunction={() => {getArticleList(match.params.id)}}>

        {
          articles?.length
          ?
            <IonList>
              {
                articles?.map((a, index) => {
                  return (
                      <IonItem onClick={()=>openModal(ARTICLE, a.nid)} key={index}>
                        <img src={a?.field_image || placeholderImage} className={styles.article_image} />
                        <IonLabel>{a?.title}</IonLabel>
                      </IonItem>
                  )
                })
              }
            </IonList>
          :
            <NoContent text='There are no articles to view'/>
        }
        <Article />

      </DefaultTemplate>
  )
}