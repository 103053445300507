import {connect} from 'react-redux'
import {EventList} from "./event-list"
import {getEventList, getListViewEvents} from "./actions"
import {openModal, colourMode} from "app";
import {events} from "../selectors";

const mapStateToProps = (state) => ({
  events: events(state),
  colourMode: colourMode(state),
})

const mapDispatchToProps = (dispatch) => ({
  getEvents: (listId) => dispatch(getEventList(listId)),
  openModal: (modal, modalData) => dispatch(openModal(modal, modalData))
})

export const EventListConnected = connect(mapStateToProps, mapDispatchToProps)(EventList)