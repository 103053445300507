import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {DefaultTemplate, NoContent} from '../../components'
import {Event} from './modals'
import {EVENT} from "../../constants/modals";
import { Eventcalendar } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';

export const EventList = ({
                            match,
                            getEvents,
                            events,
                            openModal,
                            colourMode,
                          }) => {

  useEffect(() => {
    getEvents(match.params.id)
  }, [getEvents])

  const view = useMemo(() => {
    return {
      calendar: { type: 'month' },
      agenda: { type: 'day' }
    };
  }, []);

  const onEventClick = useCallback((event) => {
    openModal(EVENT, event.event.nid)
  }, []);


  return (
      <DefaultTemplate refreshFunction={() => {getEvents(match.params.id)}}>
        {
          events?.length
          ?
            <Eventcalendar
              theme="material"
              themeVariant={colourMode}
              clickToCreate={false}
              dragToCreate={false}
              dragToMove={false}
              dragToResize={false}
              data={events}
              view={view}
              onEventClick={onEventClick}
            />
          :
            <NoContent text='There are no events to view'/>
        }
        <Event />

      </DefaultTemplate>
  )
}