import React from 'react'
import {Network} from "@capacitor/network";
import styles from './styles.module.scss'
import {IonButton} from "@ionic/react";

export const NetworkStatus = ({ updateNetworkStatus, networkStatus, retry }) => {

  Network.addListener('networkStatusChange', status => {
    updateNetworkStatus(status.connected)
  });

  return (
    <>
      {
        networkStatus === 'OFFLINE' &&
        <div className={styles.overlay}>
          <div>
            <h1>Offline</h1>
          </div>
        </div>
      }
      {
        networkStatus === 'SLOW' &&
        <div className={styles.overlay}>
          <div>
            <h1>Slow</h1>
            <IonButton size='small' onClick={() => retry()}>Retry</IonButton>
          </div>
        </div>
      }
    </>
  )
}