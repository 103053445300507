import {get, patch} from "./api";

export const dlMenu = async (dispatch, nid) => {
  let menu = await get('/viewsjson/app-menu', `nid=${nid}`, dispatch)

  // TODO: remove this when sub-menu api has been set up
  if(nid){
    menu = [
        {title: "School info", field_icon: null, nid: "9", type: "page"},
        {title: "Alerts", field_icon: null, nid: "11", type: "alert_list"}
    ]
  }

  // if(menu?.length === 1){
  //   localStorage.setItem('menu', JSON.stringify(menu[0]))
  // }

  return menu
}

export const dlPageContent = async (nid, dispatch) => {
  const pageContent = await get(`/node/${nid}`, null, dispatch)

  const title = (pageContent?.title?.length) ? pageContent.title[0].value : null
  const body = (pageContent?.body?.length) ? pageContent.body[0].value : null

  return {
    title: title,
    body: body
  }
}

export const dlAlerts = async (dispatch) => {
  const alerts = await get('/app/alert_list', null, dispatch)

  // Convert read to boolean
  alerts?.forEach(alert => {
    alert.read = Boolean(parseInt(alert.read))
  })

  return alerts
}

export const dlAlert = async (alertId, dispatch) => {
  const alert = await get(`/node/${alertId}`, null, dispatch)

  alert(JSON.stringify(alert))
}

export const ulAlert = async (a, dispatch) => {

  const upload = {
    "nid":[{"value": a.audit_id}],
    "type":[{"target_id": "notification_audit"}],
    "field_read":[{"value": a.read}]
  }

  const uploaded = await patch(`/node/${a.audit_id}`, upload, null, dispatch)

  if(uploaded.message){
    alert(uploaded.message)
    return false
  }

  return true
}

export const dlArticleList = async (listId, dispatch) => {
  const articles = await get('/viewsjson/articles', `list=${listId}`, dispatch)

  return articles
}

export const dlArticle = async(articleId, dispatch) => {
  const article = await get('/viewsjson/article', `id=${articleId}`, dispatch)

  return article?.length ? article[0] : null
}

export const dlContactPage = async (nodeId, dispatch) => {
  const contactPage = await get('/viewsjson/contact-items', `contact=${nodeId}`, dispatch)
  return contactPage
}

export const dlEventList = async (listId, dispatch) => {
  const articles = await get('/viewsjson/events', `list=${listId}`, dispatch)

  return articles
}

export const dlEvent = async (eventId, dispatch) => {
  const event = await get('/viewsjson/event', `id=${eventId}`, dispatch)

  return event?.length ? event[0] : null
}

export const dlPdfList = async (listId, dispatch) => {
  const pdfs = await get('/viewsjson/pdfs', `list=${listId}`, dispatch)

  return pdfs?.length ? pdfs : []
}

export const dlAttendance = async (studentId, dispatch) => {
  const attendance = await get('/attendance', `student=${studentId}`, dispatch)
  return attendance
}

export const dlPhotoLibrary = async (galleryId, dispatch) => {

  return [
    {
      image: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/GoldenGateBridge-001.jpg/1200px-GoldenGateBridge-001.jpg",
      caption: "San Francisco"
    },
    {
      image: "https://cdn.britannica.com/s:800x450,c:crop/35/204435-138-2F2B745A/Time-lapse-hyper-lapse-Isle-Skye-Scotland.jpg",
      caption: "Scotland"
    },
    {
      image: "https://static2.tripoto.com/media/filter/tst/img/735873/TripDocument/1537686560_1537686557954.jpg",
      caption: "Darjeeling"
    },
    {
      image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Palace_of_Fine_Arts_%2816794p%29.jpg/1200px-Palace_of_Fine_Arts_%2816794p%29.jpg",
      caption: "San Francisco"
    },
    {
      image: "https://i.natgeofe.com/n/f7732389-a045-402c-bf39-cb4eda39e786/scotland_travel_4x3.jpg",
      caption: "Scotland"
    },
    {
      image: "https://www.tusktravel.com/blog/wp-content/uploads/2020/07/Best-Time-to-Visit-Darjeeling-for-Honeymoon.jpg",
      caption: "Darjeeling"
    },
    {
      image: "https://www.omm.com/~/media/images/site/locations/san_francisco_780x520px.ashx",
      caption: "San Francisco"
    },
    {
      image: "https://images.ctfassets.net/bth3mlrehms2/6Ypj2Qd3m3jQk6ygmpsNAM/61d2f8cb9f939beed918971b9bc59bcd/Scotland.jpg?w=750&h=422&fl=progressive&q=50&fm=jpg",
      caption: "Scotland"
    },
    {
      image: "https://www.oyorooms.com/travel-guide/wp-content/uploads/2019/02/summer-7.jpg",
      caption: "Darjeeling"
    }
  ];
}