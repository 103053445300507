import {setBusyIndicator} from "../../app";
import {setPhotoGallery} from "./action-types";
import {dlPhotoLibrary} from "../../utils/api/page";

export const getPhotoGallery = (galleryId) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const gallery = await dlPhotoLibrary(galleryId, dispatch)

    dispatch(setPhotoGallery(gallery))
    dispatch(setBusyIndicator(false))
  }

}