import {connect} from 'react-redux'
import {Event} from "./event"
import {getOpenModal, dismissModal, getOpenModalData} from '../../../../app'
import {EVENT} from "../../../../constants/modals";
import {getEvent} from '../../actions'
import {selectedEvent} from "../../../selectors";

const mapStateToProps = (state) => ({
  isOpen: getOpenModal(state, EVENT),
  eventId: getOpenModalData(state),
  event: selectedEvent(state)
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(dismissModal()),
  getEvent: (eventId) => dispatch(getEvent(eventId))
})

export const EventConnected = connect(mapStateToProps, mapDispatchToProps)(Event)