import {setBusyIndicator} from "../../app";
import {dlEvent, dlEventList} from "../../utils/api/page";
import {setEventList, setEventsForListView, setSelectedEvent} from "./action-types"
import {sortEventsForListView} from './logic'

export const getEventList = (listId) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const events = await dlEventList(listId, dispatch)
    dispatch(setEventList(events))

    dispatch(setBusyIndicator(false))

    return events
  }
}

export const getEvent = (eventId) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const event = await dlEvent(eventId)
    dispatch(setSelectedEvent(event))

    dispatch(setBusyIndicator(false))

    return event
  }
}

export const getListViewEvents = (events) => {
  return (dispatch) => {
    const sortedEvents = sortEventsForListView(events)

    dispatch(setEventsForListView(sortedEvents))
  }
}