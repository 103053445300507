import {get} from "./api";

export const getAccess = async (accessCode, dispatch) => {
  const accessDetails = await get('/school_access', `code=${accessCode}`, dispatch)

  if(accessDetails?.length === 1){
    localStorage.setItem('school_details', JSON.stringify(accessDetails[0]))
    localStorage.setItem('access_code', accessCode)
  }

  return accessDetails
}

export const dlSettings = async (dispatch) => {
  const settings = await get('/viewsjson/settings', null, dispatch)

  if(!settings?.length){
    return null
  }

  return settings[0]

}