import {connect} from 'react-redux'
import {AlertList} from "./alert-list"
import {getAlerts} from "../actions"
import {alerts, selectedAlert} from "../selectors"
import {openModal} from '../../app'

const mapStateToProps = (state) => ({
  alerts: alerts(state),
  selectedAlert: selectedAlert(state)
})

const mapDispatchToProps = (dispatch) => ({
  getAlerts: () => dispatch(getAlerts()),
  openModal: (modal, modalData) => dispatch(openModal(modal, modalData)),
})

export const AlertListConnected = connect(mapStateToProps, mapDispatchToProps)(AlertList)