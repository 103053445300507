import {connect} from 'react-redux'
import {PhotoGallery} from './photo-gallery'
import {getPhotoGallery} from "./actions";
import {photoGallery} from "../selectors";

const mapStateToProps = (state) => ({
  photoGallery: photoGallery(state)
})

const mapDispatchToProps = (dispatch) => ({
  getPhotoGallery: (galleryId) => dispatch(getPhotoGallery(galleryId))
})

export const PhotoGalleryConnected = connect(mapStateToProps, mapDispatchToProps)(PhotoGallery)