import {downloadDetails, isLoggedIn, login, logout, dlDependants, tokenActive, dlUserRoles} from "../utils/api/user"
import {setLoginDetails, setLoggedIn, setUserDetails, clearUserData, setLoginMessageSuccess, setDependants, setSelectedDependant, setRoles} from './action-creators'
import {getAccess} from "../utils/api/app";
import {setOrganisationSelected, setSchoolDetails, setValidLoginToken} from "../app/action-types";
import {deleteCurrentToken} from "../utils/api/push-notifications";
import store from "../store";
import { startUp } from "app/logic";
import {getSettings, setBusyIndicator} from "../app";
import {profilePic} from "../icons";

export const resetApp = () => {
  return dispatch => {
    localStorage.clear()

    // dispatch(setSchoolDetails(null))
    dispatch(setOrganisationSelected(false))
  }
}

export const doLogin = (username, password) => {
  return async dispatch => {
    dispatch(setBusyIndicator(true))

    const loginResult = await login(username, password, dispatch)
    loginResult.success ? dispatch(downloadUserDetails()) : dispatch(setLoginMessage(loginResult.data.message))

    if (loginResult.success) dispatch(setValidLoginToken(true))

    dispatch(setBusyIndicator(false))

    return loginResult.success
  };
}

export function setLoginMessage(message) {
  return dispatch =>{
    dispatch(setLoginMessageSuccess(message))
  }
}

export const loggedIn = () => {
  return localStorage.getItem('isLoggedIn')
}

export const downloadUserDetails = () => {
  return async (dispatch) => {

    let userDetails = await downloadDetails(dispatch)
    const roles = await dlUserRoles(dispatch)

    if(userDetails && !userDetails.user_picture) {
      userDetails.user_picture = profilePic
    }

    dispatch(setUserDetails(userDetails))
    dispatch(setRoles(roles))

    return true
  }
}

export const loadUser = () => {
  return (dispatch) => {
    const loginDetails = localStorage.getItem('loginDetails')

    if(loginDetails){
      dispatch(setLoginDetails(JSON.parse(loginDetails)))
    }
  }
}

export const checkAccessCode = (accessCode) => {
  return async (dispatch) => {

    let accessDetails = await getAccess(accessCode, dispatch)

    if (accessDetails.message) {
      dispatch(setOrganisationSelected(false))
      return {
        success: false,
        message: accessDetails.message
      }
    }

    if (accessDetails?.length === 0) {
      dispatch(setOrganisationSelected(false))
      return {
        success: false,
        message: 'Invalid Access Code'
      }
    }

    dispatch(setSchoolDetails(accessDetails[0]))
    dispatch(setOrganisationSelected(true))

    return {
      success: true
    }
  }
}

export const doLogout = () => {
  return async (dispatch) => {

    // This must be done before the user is logged out because
    // anonymous users don't have permission to delete tokens
    const deleteResponse = deleteCurrentToken()

    const response = await logout(store.getState().userManagement.logoutToken)
    if (response){
      dispatch(clearUserData())
      dispatch(setValidLoginToken(false))
    }

    return true
  };
}

export const getDependants = () => {
  return async (dispatch) => {
    const dependants = await dlDependants(dispatch)
    dispatch(setDependants(dependants))
  }
}

export const selectDependant = (dependantId) => {
  return (dispatch) => {
    dispatch(setSelectedDependant(dependantId))
  }
}

export const checkLoginToken = () => {
  return async (dispatch) => {
    const activeToken = await tokenActive()
    dispatch(setValidLoginToken(activeToken))
  }
}

