import React, {useEffect} from 'react'
import {IonList, IonItem, IonLabel} from '@ionic/react'
import {DefaultTemplate} from '../../components'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import styles from './styles.module.scss'
import {Browser} from '@capacitor/browser';
import {NoContent} from '../../components'

export const PDFList = ({
                          getPdfList,
                          pdfList,
                          match
                        }) => {

  useEffect(() => {
    getPdfList(match.params.id)
  }, [getPdfList])

  const openPdf = async (url) => {
    await Browser.open({ url: url, windowName: '_self' });
  }

  return (
      <DefaultTemplate>
        {
          pdfList?.length
          ?
            <IonList>
              {
                pdfList?.map((pdf) => {
                  return (
                    <IonItem className={styles.pdf_item}
                      onClick={() => {
                        openPdf(JSON.parse(localStorage.getItem('school_details'))?.url + pdf.pdf_url)
                      }}>
                      <FontAwesomeIcon icon={faFilePdf} />
                      <IonLabel>{pdf.title}</IonLabel>
                    </IonItem>
                  )
                })
              }
            </IonList>
          :
            <NoContent text='There are no documents to view' />
        }
      </DefaultTemplate>
  )
}