import {connect} from 'react-redux'
import {markAlertAsRead, selectAlert} from "../../../actions";
import {AlertItem} from "./alert-item";
import {getOpenModal, dismissModal, getOpenModalData} from '../../../../app'
import {ALERT_ITEM} from "../../../../constants/modals";
import {userId} from "../../../../user-management";

const mapStateToProps = (state) => ({
  isOpen: getOpenModal(state, ALERT_ITEM),
  alert: getOpenModalData(state),
  userId: userId(state),
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(dismissModal()),
  clearSelectedId: () => dispatch(selectAlert(null)),
  markAsRead: (alertId, userId) => dispatch(markAlertAsRead(alertId, userId))
})

export const AlertItemConnected = connect(mapStateToProps, mapDispatchToProps)(AlertItem)