import { connect } from 'react-redux'
import { Logo } from "./logo"
import { coverPicture, coverPictureDark } from "app"
import { colourMode } from 'app'

const mapStateToProps = (state) => ({
  coverPicture: coverPicture(state),
  coverPictureDark: coverPictureDark(state),
  colourMode: colourMode(state)
})

export const LogoConnected = connect(mapStateToProps, null)(Logo)