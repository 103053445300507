import {connect} from 'react-redux'
import {Page} from './page'
import {getPageContent} from "../actions";
import {pageContent} from '../selectors'

const mapStateToProps = (state) => ({
  pageContent: pageContent(state),
})

const mapDispatchToProps = (dispatch) => ({
  getPageContent: (nid) => dispatch(getPageContent(nid)),
})

export const PageConnected = connect(mapStateToProps, mapDispatchToProps)(Page)