import {dlAlert, dlAlerts, dlContactPage, dlMenu, dlPageContent, ulAlert, dlPdfList} from '../utils/api/page'
import {
  setMenu,
  setSubMenu,
  setAlerts,
  setPageContent,
  dispatchBasicAction,
  setSelectedAlertId,
  SET_CONTACT_PAGE_DETAILS,
  SET_PDF_LIST,
  setAlertRead
} from './action-types'
import {setBusyIndicator} from '../app'
import {readNotification} from "../utils/api/push-notifications";

export const getMenu = () => {
  return async (dispatch) =>{
    const menu = await dlMenu(dispatch)

     dispatch(setMenu(menu))
    return menu

  }
}

export const getSubMenu = (pageId) => {
  return async (dispatch) =>{
    let menu = []

    if(pageId){
      menu = await dlMenu(dispatch, pageId)
    }

    dispatch(setSubMenu(menu))
    return menu
  }
}

export const getPageContent = (nid) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const pageContent = await dlPageContent(nid, dispatch)
    dispatch(setPageContent(pageContent))

    dispatch(setBusyIndicator(false))
  }
}

export const getAlerts = () => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const alerts = await dlAlerts(dispatch)

    dispatch(setAlerts(alerts))
    dispatch(setBusyIndicator(false))

    return alerts
  }
}

export const markAlertAsRead = (userId, alertId) => {
  return (dispatch) => {
    readNotification(userId, alertId)
    dispatch(setAlertRead(alertId))
  }
}

export const getContactDetails = (nodeId) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const contactPage = await dlContactPage(nodeId, dispatch)
    dispatch(dispatchBasicAction(SET_CONTACT_PAGE_DETAILS, contactPage))

    dispatch(setBusyIndicator(false))
  }
}

export const setPdfLIst = (listId) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const pdfs = await dlPdfList(listId, dispatch)
    dispatch(dispatchBasicAction(SET_PDF_LIST, pdfs))

    dispatch(setBusyIndicator(false))
  }
}

export const selectAlert = (alertId) => {
  return (dispatch) => {
    dispatch(setSelectedAlertId(alertId))
  }
}