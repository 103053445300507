import {connect} from 'react-redux'
import {EventLog} from './event-log'
import {eventLogSelectors} from './state'

const mapStateToProps = (state) => ({
  logs: eventLogSelectors.logs(state)
})

const mapDispatchToProps = (dispatch) => ({
})

export const EventLogConnected = connect(mapStateToProps, mapDispatchToProps)(EventLog)