import {connect} from 'react-redux'
import {Menu} from './side-menu'
import {isAdmin} from '../../user-management'
import {appVersionNumber} from '../../app'

const mapStateToProps = (state) => ({
  isAdmin: isAdmin(state),
  appVersionNumber: appVersionNumber(state)
})

const mapDispatchToProps = (dispatch) => ({
})

export const MenuConnected = connect(mapStateToProps, null)(Menu)