import {
  IonContent,  IonHeader, IonPage, IonToolbar, IonButtons,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonBackButton} from "@ionic/react";
import React, {useEffect} from "react";
import {useLocation} from 'react-router-dom'
import{ToolbarActionButton} from '../../toolbar-action-button'
import styles from './styles.module.scss'


export const DefaultTemplate = ({children,
                                  actionButtonIcon,
                                  actionButtonOnClick,
                                  refreshFunction,
                                  appTitle,
                                }) => {

  const location = useLocation()

  const doRefresh = async (event) => {
    await refreshFunction()
    event.detail.complete();
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar >

          <IonButtons slot="start">
            { (location.pathname === '/home' || location.pathname === '/account' || location.pathname === '/event-log') && <IonMenuButton /> }
            <IonBackButton />
          </IonButtons>

          <IonTitle className={styles.title}>{appTitle}</IonTitle>

          <IonButtons className={styles.right_buttons} slot="end">
            {
              actionButtonIcon && actionButtonOnClick &&
                <ToolbarActionButton icon={actionButtonIcon} onClickFunction={actionButtonOnClick} />
            }
          </IonButtons>

        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {refreshFunction &&
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
        }
        <div className={styles.page_container}>
          {children}
        </div>
      </IonContent>

    </IonPage>

  )
}