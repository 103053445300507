import { connect } from 'react-redux'
import { Attendance } from "./attendance";
import { selectedStudent, userRoles } from '../../user-management'
import { getAttendance } from "./state";
import { attendanceData } from "./state/selectors";
import { colourMode } from 'app'
import {dependantsCount} from "../../user-management/selectors";
import {getDependants} from "../../user-management";

const mapStateToProps = (state) => ({
  selectedStudent: selectedStudent(state),
  attendanceData: attendanceData(state),
  colourMode: colourMode(state),
  dependantsCount: dependantsCount(state),
  userRoles: userRoles(state),
})

const mapDispatchToProps = (dispatch) => ({
  getAttendance: (studentId) => dispatch(getAttendance(studentId)),
  getDependants: () => dispatch(getDependants()),
})

export const AttendanceConnected = connect(mapStateToProps, mapDispatchToProps)(Attendance)