import React from 'react'
import styles from './styles.module.scss'
import {useHistory} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Browser} from "@capacitor/browser";

export const MainMenu = ({menu, url}) => {

  let history = useHistory()

  const handleClick = (menuItem) => {
    switch(menuItem.type){
      case 'webform_link':
        Browser.open({url: `${url}${menuItem.webform}`, windowName: '_self'})
        break

      case 'webpage':
        Browser.open({url: `${menuItem.webpage}`, windowName: '_self'})
        break

      default:
        history.push(`/${menuItem.type}/${menuItem.nid}`)}
    }

  return (
    <div className={styles.icon_menu}>
      {
        menu?.map((item, index) => {
          return (
            <div className={styles.icon_menu_item}
                 onClick={ () => handleClick(item) }
                 key={index}>

              <div  className={styles.menu_icon}>
                <FontAwesomeIcon icon={item.icon ? item.icon : "question"} />
              </div>

              <div className={styles.menu_text}>{item.title}</div>
            </div>
          )
        })
      }
    </div>
  )
}