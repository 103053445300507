import { connect } from 'react-redux'
import { UserAccount } from './user-account'
import { doLogout } from '../actions'
import { userDetails } from "../selectors";

const mapStateToProps = (state) => ({
  userDetails: userDetails(state)
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(doLogout())
})

export const UserAccountConnected = connect(mapStateToProps, mapDispatchToProps)(UserAccount)