import { checkAccessCode, checkLoginToken } from "../user-management";
import { accessCode } from 'constants/constants.js'
import {setBusyIndicator, getAppVersion} from "./actions";
import {eventLogActions} from '../pages/event-log'

export const getStartPage = (schoolSelected, validLoginToken) => {

  if (!schoolSelected) return '/access'

  if (validLoginToken) return '/home'

  return "/login"
}

export const startUp = async (dispatch) => {

  dispatch(eventLogActions.addToEventLog('General', 'startup'))

  dispatch(setBusyIndicator(true))

  dispatch(getAppVersion())

  let code = accessCode
  if (!code) {
    code = localStorage.getItem('access_code')
  }
  await dispatch(checkAccessCode(code))

  await dispatch(checkLoginToken())

  dispatch(setBusyIndicator(false))
}