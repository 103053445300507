import { createSelector } from 'reselect'
import {roles} from '../constants'

const localState = (state) => state?.userManagement

export const userDetails = (state) =>
    state?.userManagement?.userDetails

export const userId = createSelector(
  userDetails,
  (userDetails) => userDetails?.id
)

export const userRoles = (state) =>
  state?.userManagement?.roles

export const userDisplayName = createSelector(
  userDetails,
  (userDetails) => userDetails?.display_name
)

export const dependants = (state) =>
  state?.userManagement?.dependants

export const selectedDependantId = createSelector(
  localState,
  dependants,
  (state, dependants) => dependants?.length === 1 ? dependants[0].uid : state?.selectedDependantId
)

export const selectedDependant = createSelector(
  dependants,
  selectedDependantId,
  (dependants, id) => dependants?.find(d => d.uid == id)
)

export const isStudent = (state) => {
  return state?.userManagement?.roles?.find(r => r === roles.STUDENT)
}

export const isAdmin = createSelector(
  userRoles,
  (userRoles) => userRoles?.find(r => r === roles.ADMIN)
)

export const selectedStudent = createSelector(
  isStudent,
  selectedDependant,
  userDetails,
  (isStudentUser, selectedDependant, userDetails) => {
    return isStudentUser
      ?
      { uid: userDetails.id, external_id: userDetails.external_id }
      :
      selectedDependant
  }
)

export const dependantsCount = (state) =>
  state?.userManagement?.dependants?.length
