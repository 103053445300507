import {DefaultTemplate} from '../../components'
import {Dependants} from '../../user-management'
import {useEffect, useState, useMemo, useCallback} from "react";
import { Eventcalendar } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import styles from './styles.module.scss'
import {canSelectDependants, canViewAttendance} from './logic'

export const Attendance = ({ selectedStudent,
                             getAttendance,
                             attendanceData,
                             colourMode,
                             getDependants,
                             userRoles,
                             dependantsCount,
                           }) => {

  const [selectingStudent, setSelectingStudent] = useState(false)

  useEffect(() => {
    if (allowDependantSelection) getDependants()
  }, [getDependants, userRoles])

  useEffect(() => {
    if (selectedStudent) getAttendance(selectedStudent.uid)
  }, [selectedStudent])

  const allowDependantSelection = useMemo(() => {
    return canSelectDependants(userRoles)
  }, [userRoles])

  const allowAttendanceView = useMemo(() => {
    return canViewAttendance(userRoles)
  }, [userRoles])

  const view = useMemo(() => {
    return {
      calendar: { type: 'week' },
      agenda: { type: 'day' }
    };
  }, []);

  return (
    <>
      {
        allowAttendanceView
          ?
          <DefaultTemplate
            actionButtonIcon={allowDependantSelection && dependantsCount > 1 && "people-arrows"}
            actionButtonOnClick={()=>setSelectingStudent(true)}
            refreshFunction={() => getAttendance(selectedStudent.uid)}>

            <div className={styles.header_label}>
              {
                selectedStudent
                  ?
                  <p>Viewing {selectedStudent.first_name} {selectedStudent.last_name}'s attendance report</p>
                  :
                  <p>Please use the button in the toolbar to select an attendance report</p>
              }
            </div>

            {
              selectedStudent &&
              <Eventcalendar
                theme="material"
                themeVariant={colourMode}
                clickToCreate={false}
                dragToCreate={false}
                dragToMove={false}
                dragToResize={false}
                data={attendanceData}
                view={view}
              />
            }

          </DefaultTemplate>
          :

          <DefaultTemplate>
            <div className={styles.header_label}>
              Only parents and students can view attendance
            </div>
          </DefaultTemplate>
      }

      { allowDependantSelection &&
          <Dependants isOpen={selectingStudent} close={() => setSelectingStudent(false)} /> }
    </>
  )
}