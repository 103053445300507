import { Carousel } from 'react-carousel-minimal'
import {DefaultTemplate} from '../../components'
import {useEffect} from "react";

export const PhotoGallery = ({ match, getPhotoGallery, photoGallery }) => {

  useEffect(() => {
    getPhotoGallery(match.params.id)
  }, [getPhotoGallery])

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  return (
      <DefaultTemplate>
        {photoGallery &&
          <Carousel
              data={photoGallery}
              time={2000}
              width="850px"
              height="500px"
              captionStyle={captionStyle}
              radius="10px"
              slideNumber={true}
              slideNumberStyle={slideNumberStyle}
              captionPosition="bottom"
              automatic={true}
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="darkgrey"
              slideImageFit="cover"
              thumbnails={true}
              thumbnailWidth="100px"
              style={{
                textAlign: "center",
                maxWidth: "850px",
                maxHeight: "500px",
                margin: "40px auto",
              }}
          />
        }
      </DefaultTemplate>
  );
}