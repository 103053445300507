import { createSelector } from 'reselect'

const localState = (state) => state?.page

export const menu = (state) =>
    state?.page?.menu

export const subMenu = (state) =>
    state?.page?.subMenu

export const pageContent = (state) =>
    state?.page?.pageContent

export const alerts = (state) =>
    state?.page?.alerts

export const articles = (state) =>
    state?.page?.articleList

export const selectedArticle = (state) =>
    state?.page?.selectedArticle

export const contactDetails = (state) =>
    state?.page?.contactPage

export const events = (state) =>
  state?.page?.eventList

export const selectedEvent = (state) =>
    state?.page?.selectedEvent

export const pdfList = (state) =>
    state?.page?.pdfList

export const photoGallery = (state) =>
    state?.page?.photoGallery

export const schoolDetails = (state) =>
    state?.app?.schoolDetails

export const selectedAlertId = (state) =>
  state?.page?.selectedAlertId

export const selectedAlert = createSelector(
  localState,
  selectedAlertId,
  (state, selectedAlertId) => selectedAlertId ? state?.alerts?.find(a => a.alert_id == selectedAlertId) : null
)

