import {connect} from 'react-redux'
import {PDFList} from "./pdf-list"
import {setPdfLIst} from "../actions";
import {pdfList} from '../selectors'

const mapStateToProps = (state) => ({
  pdfList: pdfList(state)
})

const mapDispatchToProps = (dispatch) => ({
  getPdfList: (listId) => dispatch(setPdfLIst(listId))
})

export const PDFListConnected = connect(mapStateToProps, mapDispatchToProps)(PDFList)