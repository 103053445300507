import React from "react"

export const Logo = ({
                       coverPicture,
                       coverPictureDark,
                       colourMode
                     }) => {
  return (
    colourMode === 'light'
    ?
      <img src={coverPicture} alt="Light" />
    :
      <img src={coverPictureDark} alt="Dark" />
  )
}