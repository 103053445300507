import styles from './styles.module.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const ToolbarActionButton = ({icon, onClickFunction}) =>{
  return (
    <div className={styles.toolbar_action_button}>
      <FontAwesomeIcon icon={icon} onClick={onClickFunction ? ()=>{onClickFunction()} : null} />
    </div>
  )
}