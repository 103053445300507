import { IonModal } from "@ionic/react"
import { ModalTemplate } from '../../../../components'
import React, { useEffect } from "react";
import styles from './styles.module.scss'
import {formatISODate} from 'utils/helpers/date.js'
import {formatLongDate} from "../../../../utils/helpers/date";

export const AlertItem = ({
                            isOpen,
                            close,
                            alert,
                            userId,
                            clearSelectedId,
                            markAsRead,
                          }) => {

  useEffect(() => {
    if (alert && !alert.read) markAsRead(userId, alert.alert_id)
  }, [alert, userId])

  useEffect(() => {
    clearSelectedId()
  }, [alert, clearSelectedId])

  return (
      <IonModal isOpen={isOpen} className={styles.test} backdropDismiss={true} showBackdrop={true}>
        <ModalTemplate title='Alert'
                       closeMethod={() => {close()}}>



          <h1>{alert?.alert_title}</h1>
          <p>{formatLongDate(alert?.date)}</p>
          <div dangerouslySetInnerHTML={{__html: alert?.alert_content}}></div>

        </ModalTemplate>
      </IonModal>
  )
}