import {
  SET_MENU,
  SET_SUB_MENU,
  SET_ALERTS,
  SET_PAGE_CONTENT,
  SET_CONTACT_PAGE_DETAILS,
  SET_PDF_LIST,
  SET_SELECTED_ALERT_ID,
  SET_ALERT_READ,
} from './action-types'

import {SET_ARTICLE_LIST, SET_ARTICLE} from './article_list'
import {SET_EVENT_LIST, SET_EVENT, SET_EVENTS_FOR_LIST_VIEW} from './event-list'
import {SET_PHOTO_GALLERY} from './photo-gallery'
import {markAlertAsRead} from "./reducer-logic";

export function pageReducer(state = {}, action){
  switch (action.type) {

    case SET_MENU:
      return {
        ...state,
        menu: action.payload
      }

    case SET_SUB_MENU:
      return {
        ...state,
        subMenu: action.payload
      }

    case SET_PAGE_CONTENT:
      return {
        ...state,
        pageContent: action.payload
      }

    case SET_ALERTS:
      return {
        ...state,
        alerts: action.payload
      }

    case SET_ARTICLE_LIST:
      return {
        ...state,
        articleList: action.payload
      }

    case SET_ARTICLE:
      return {
        ...state,
        selectedArticle: action.payload
      }

    case SET_CONTACT_PAGE_DETAILS:
      return {
        ...state,
        contactPage: action.payload
      }

    case SET_EVENT_LIST:
      return {
        ...state,
        eventList: action.payload
      }

    case SET_EVENT:
      return {
        ...state,
        selectedEvent: action.payload
      }

    case SET_PDF_LIST:
      return {
        ...state,
        pdfList: action.payload
      }

    case SET_PHOTO_GALLERY:
      return {
        ...state,
        photoGallery: action.payload
      }

    case SET_SELECTED_ALERT_ID:
      return {
        ...state,
        selectedAlertId: action.payload
      }

    case SET_ALERT_READ:
      return {
        ...state,
        alerts: markAlertAsRead(state?.alerts, action.payload)
      }

    default:
      return state
  }
}