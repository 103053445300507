import {dlAttendance} from '../../../utils/api/page'
import {setAttendance} from './action-types'
import {setBusyIndicator} from "../../../app";

export const getAttendance = (studentId) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const attendance = await (dlAttendance(studentId))

    dispatch(setAttendance(attendance))
    dispatch(setBusyIndicator(false))
  }
}