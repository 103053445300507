import React, {useState} from 'react';
import { IonButton, IonItem, IonLabel, IonInput, IonAlert } from '@ionic/react'
import styles from './styles.module.scss'
import { Logo } from '../../components'
import {accessCode} from '../../constants/constants'

export const Login = ({ loginMessage,
                        setLoginMessage,
                        login,
                        resetApp}) => {

  const [username, setUsername] = useState(null)
  const [password, setPassword] = useState(null)
  const [confirmMessage, setConfirmMessage] = useState(null)

  const resetPassword = () => {
    const orgDetails = JSON.parse(localStorage.getItem('school_details'))
    const siteUrl = orgDetails.url
    window.open(`${siteUrl}/user/password`,'_blank')
  }

  return (
      <div className={styles.login_box_outer}>
        <div className={styles.login_box}>
          <div className={styles.login_box_inner}>
            <div className={styles.header}>
              <Logo />
              <p>Sign in to continue</p>
            </div>

              <div className={styles.login_container}>
                <div>
                  <IonItem>
                    <IonLabel position="stacked">Username</IonLabel>
                    <IonInput value={username} onIonChange={(e) => {
                      setUsername(e.target.value)
                    }}> </IonInput>
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked">Password</IonLabel>
                    <IonInput value={password} type={'password'} onIonChange={(e) => {
                      setPassword(e.target.value)
                    }}> </IonInput>
                  </IonItem>
                </div>

                <IonButton expand="block" color="primary" onClick={() => {login(username, password)}}>
                  Login
                </IonButton>

                <div className={styles.options}>
                  <div className={styles.forgotten} onClick={() => { resetPassword() }}>
                    Forgotten password?
                  </div>

                  {!accessCode &&
                    <div className={styles.forgotten} onClick={() => setConfirmMessage(true)}>
                      Change organisation
                    </div>
                  }
                </div>

              </div>
            </div>
          </div>

        <IonAlert
            isOpen={Boolean(loginMessage)}
            header={'Alert'}
            message={loginMessage}
            buttons={['OK']}
        />

        <IonAlert
            isOpen={confirmMessage}
            header={'Confirm!'}
            message={'Are you sure you want to change organisations?'}
            buttons={[
              {
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: 'Yes',
                handler: () => {
                  resetApp();
                }
              }
            ]}
        />

      </div>
  );
}
