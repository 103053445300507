import {IonButton, IonContent, IonHeader, IonIcon} from "@ionic/react";
import {close, create} from 'ionicons/icons'
import styles from './styles.module.scss'
import React from "react";

export const ModalTemplate = ({children, title, closeMethod}) => {
  return (
    <IonContent className={styles.modal_content_wrapper}>

      <IonHeader className={styles.modal_header}>
        <span>{title}</span>
        <IonIcon icon={close} onClick={closeMethod} />
      </IonHeader>

      <div className={styles.modal_content_wrapper}>
        {children}
      </div>

    </IonContent>
  )
}