import {setBusyIndicator} from "../../app";
import {dlArticle, dlArticleList} from "../../utils/api/page";
import {setArticleList, setSelectedList} from './action-types'

export const getArticleList = (listId) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const articles = await dlArticleList(listId, dispatch)
    dispatch(setArticleList(articles))

    dispatch(setBusyIndicator(false))

    return articles
  }
}

export const getArticle = (articleId) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const article = await dlArticle(articleId)
    dispatch(setSelectedList(article))

    dispatch(setBusyIndicator(false))

    return article
  }
}