import {connect} from 'react-redux'
import {PushNotificationHandler} from './push-notification-handler'
import {userId} from '../../user-management'
import {getAlerts} from "../../pages/actions";
import {openModal} from '../../app'
import {eventLogActions} from '../../pages/event-log'

const mapStateToProps = (state) => ({
  userId: userId(state),
})

const mapDispatchToProps = (dispatch) => ({
  getAlerts: () => dispatch(getAlerts()),
  openModal: (modal, modalData) => dispatch(openModal(modal, modalData)),
  logEvent: (details) => dispatch(eventLogActions.addToEventLog('Push Notification', details)),
})

export const PushNotificationHandlerConnected = connect(mapStateToProps, mapDispatchToProps)(PushNotificationHandler)