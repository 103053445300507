export const appBusy = (state) =>
    state?.app?.appBusy

export const appVersionNumber = (state) =>
  state?.app?.appVersionNumber

export const networkStatus = (state) =>
    state?.app?.networkStatus

export const schoolSelected = (state) =>
    state?.app?.schoolSelected

export const validLoginToken = (state) =>
    state?.app?.validLoginToken

export const isOnline = (state) =>
    state?.app?.networkStatus === 'ONLINE'

export const getOpenModal = (state, modalName) => {
  const openModals = state?.app?.modals

  if(!openModals || openModals.length === 0){
    return false
  }

  return Boolean(openModals.find(modal => modal.modalName === modalName))
}

export const getOpenModalData = (state) => {
  const openModals = state?.app?.modals

  if(!openModals || openModals.length === 0){
    return null
  }

  return openModals[openModals.length - 1]?.modalData
}

export const appName = (state) =>
    state?.app?.settings?.title

export const coverPicture = (state) =>
    state?.app?.settings?.main_image

export const coverPictureDark = (state) =>
    state?.app?.settings?.main_image_dark

export const colourMode = (state) =>
  state?.app?.colourMode