import {connect} from 'react-redux'
import {ArticleList} from "./article-list"
import {articles} from "../selectors";
import {getArticleList} from "./actions";
import {openModal} from "../../app";

const mapStateToProps = (state) => ({
  articles: articles(state)
})

const mapDispatchToProps = (dispatch) => ({
  getArticleList: (listId) => dispatch(getArticleList(listId)),
  openModal: (modal, modalData) => dispatch(openModal(modal, modalData))
})

export const ArticleListConnected = connect(mapStateToProps, mapDispatchToProps)(ArticleList)