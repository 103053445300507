import {IonModal} from "@ionic/react";
import {ModalTemplate} from "../../../../components";
import {useEffect} from "react";

export const Article = ({isOpen,
                          close,
                          articleId,
                          getArticle,
                          article}) => {

  useEffect(() => {
    articleId && getArticle(articleId)
  }, [getArticle, articleId])

  return (
      <IonModal isOpen={isOpen} backdropDismiss={true} showBackdrop={true}>
        <ModalTemplate title='Article'
                       closeMethod={() => {close()}}>

          <h1>{article?.title}</h1>
          <div dangerouslySetInnerHTML={{__html: article?.body}}></div>

        </ModalTemplate>
      </IonModal>
  )
}