import {connect} from 'react-redux'
import {Login} from './login'
import {doLogin, resetApp, setLoginMessage} from '../actions'

const mapStateToProps = (state) => ({
  isLoggedIn: state.userManagement.isLoggedIn,
  loginMessage: state.userManagement.loginFailureMessage
})

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) => dispatch(doLogin(username, password)),
  setLoginMessage: (message) => dispatch(setLoginMessage(message)),
  resetApp: () => dispatch(resetApp())
})

export const LoginConnected = connect(mapStateToProps, mapDispatchToProps)(Login)