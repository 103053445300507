import {DefaultTemplate} from '../../components'
import {IonItem, IonLabel, IonList} from "@ionic/react";

export const EventLog = ({
                           logs,
                         }) => {
  return (
    <DefaultTemplate>
      <IonList>
        {
          logs?.map((log, index) => {
            return (
              <IonItem key={index}>
                <IonLabel>
                  <p>{log.time}</p>
                  <p>{log.type}</p>
                  <p>{log.details}</p>
                </IonLabel>
              </IonItem>
            )
          })
        }
      </IonList>

    </DefaultTemplate>
  )
}