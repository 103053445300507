import React, { useEffect } from 'react'
import {DefaultTemplate, MainMenu} from '../../components'
import styles from './styles.module.scss'

export const Home = ({
                       getMenu,
                       menu,
                       coverPicture,
                       coverPictureDark,
                       schoolDetails,
                     }) => {

  useEffect(() => {
    if (!menu) getMenu()
  }, [getMenu, menu])

  return (
      <DefaultTemplate pageTitle={"Home"} refreshFunction={() => {getMenu()}}>

        <div className={styles.homepage}>

          <div className={styles.image_dark}>
            <div className={styles.main_image}>
              <img src={coverPictureDark} alt="Dark" />
            </div>
          </div>

          <div className={styles.image_light}>
            <div className={styles.main_image}>
              <img src={coverPicture} alt="Light" />
            </div>
          </div>

          <MainMenu
            menu={menu}
            url={schoolDetails.url}
          />

        </div>

      </DefaultTemplate>
  )
}