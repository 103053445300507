export const SET_BUSY = 'SET_BUSY'
export const SET_NETWORK_STATUS = 'SET_NETWORK_STATUS'
export const SET_SCHOOL_SELECTED = 'SET_SCHOOL_SELECTED'
export const SET_VALID_LOGIN_TOKEN = 'SET_VALID_LOGIN_TOKEN'
export const SET_APP_INITIALISED = 'SET_APP_INITIALISED'
export const SET_OPEN_MODAL = 'SET_OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_SCHOOL_DETAILS = 'SET_SCHOOL_DETAILS'
export const SET_COLOUR_MODE = 'SET_COLOUR_MODE'
export const SET_APP_VERSION_NUMBER = 'SET_APP_VERSION_NUMBER'

export const setBusy = (busy) =>({
  type: SET_BUSY,
  payload: busy
})

export const setAppVersionNumber = (versionNumber) =>({
  type: SET_APP_VERSION_NUMBER,
  payload: versionNumber
})

export const setNetworkStatus = (status) => ({
  type: SET_NETWORK_STATUS,
  payload: status
})

export const setOrganisationSelected = (loaded) => ({
  type: SET_SCHOOL_SELECTED,
  payload: loaded
})

export const setSchoolDetails = (details) => ({
  type: SET_SCHOOL_DETAILS,
  payload: details
})

export const setValidLoginToken = (valid) => ({
  type: SET_VALID_LOGIN_TOKEN,
  payload: valid
})

export const setOpenModal = (modal, modalData) => ({
  type: SET_OPEN_MODAL,
  payload: { modal: modal, modalData: modalData }
})

export const closeModal = () => ({
  type: CLOSE_MODAL
})

export const setSettings = (settings) => ({
  type: SET_SETTINGS,
  payload: settings
})

export const setColourMode = (colourMode) => ({
  type: SET_COLOUR_MODE,
  payload: colourMode
})
