import { connect } from 'react-redux'
import { GoogleCalendar } from './google-calendar'
import { colourMode } from "app";
import {menu} from "../selectors";
import {setBusyIndicator} from "../../app";

const mapStateToProps = (state) => ({
  colourMode: colourMode(state),
  menuItems: menu(state)
})

const mapDispatchToProps = (dispatch) => ({
  showBusy: (visible) => dispatch(setBusyIndicator(visible))
})

export const GoogleCalendarConnected = connect(mapStateToProps, mapDispatchToProps)(GoogleCalendar)