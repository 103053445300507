import { IonAlert, IonButton, IonInput, IonItem } from "@ionic/react";
import React, { useState } from "react";
import styles from './styles.module.scss'
import { Logo } from "../../components";

export const Access = ({ checkAccessCode }) => {

  const [message, setMessage] = useState(null)
  const [accessCode, setAccessCode] = useState(null)

  return (
        <div className={styles.access_box_outer}>
          <div className={styles.access_box}>
            <div className={styles.access_box_inner}>
              <div className={styles.header}>
              </div>

              <div className={styles.access_container}>
          
                  <p>Enter the access code your organisation has provided you with to gain access to the app</p>
                  <IonItem className={styles.access_code}>
                    <IonInput placeholder='Access Code' type="tel" inputMode='numeric' value={accessCode} onIonChange={(e) => {
                      setAccessCode(e.target.value)
                    }}> </IonInput>
                  </IonItem>

                  <IonButton expand="block" color="primary" onClick={() => {checkAccessCode(accessCode)}}>
                    Get Access
                  </IonButton>
                <div>
              </div>
            </div>
          </div>
        </div>

      <IonAlert
          isOpen={!!message}
          onDidDismiss={() => setMessage(null)}
          header={'Alert'}
          message={message}
          buttons={['OK']}
      />

    </div>
  )
}