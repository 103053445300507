import {deleteToken, downloadDeviceTokens, readNotification, uploadToken} from "../../utils/api/push-notifications";

export const syncDeviceToken = async (uid, deviceToken, platform, logEvent) => {

  logEvent(`Uploading token (${platform})`)

  // We only want to save device ids for logged-in users
  if(!uid){
    logEvent(`No user id - cannot upload (${platform})`)
    return
  }

  // There may or may not already be a device token stored locally
  let currentToken = localStorage.getItem('device_token') ? JSON.parse(localStorage.getItem('device_token')) : null

  // This should never happen
  if(currentToken && currentToken.uid != uid){
    await deleteToken(currentToken.id)
  }

  logEvent(`Checking tokens on the server (${platform})`)

  // The token for this device may already be stored on the server
  const serverTokens = await downloadDeviceTokens(uid)

  // If the server already has this device id then
  // there's nothing else to do
  if (serverTokens?.findIndex(i => i.token === deviceToken) > -1){
    logEvent(`Token already exists on server - no need to upload (${platform})`)
    return
  }

  const newToken = await uploadToken(uid, deviceToken, platform)

  if(newToken){
    logEvent(`Token uploaded (${platform})`)
    localStorage.setItem('device_token', JSON.stringify({"id": newToken.id[0].value, "uid": uid, "token": newToken.token[0].value}))
  }
  else{
    logEvent(`Token failed to upload (${platform})`)
    localStorage.removeItem('device_token')
  }
}

