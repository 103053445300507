import * as actionTypes from './action-types'

export function eventLogReducer(state = {logs: []}, action){
  switch (action.type) {
    case actionTypes.ADD_TO_EVENT_LOG:
      return {
        ...state,
        logs: [...state?.logs, action.payload]
      }

    default:
      return state
  }
}