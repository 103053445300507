import {connect} from 'react-redux'
import {ContactUs} from "./contact-us"
import {contactDetails} from '../selectors'
import {getContactDetails} from '../actions'

const mapStateToProps = (state) => ({
  contactDetails: contactDetails(state)
})

const mapDispatchToProps = (dispatch) => ({
  getContactDetails: (nodeId) => dispatch(getContactDetails(nodeId))
})

export const ContactUsConnected = connect(mapStateToProps, mapDispatchToProps)(ContactUs)