export const SET_ARTICLE_LIST = 'SET_ARTICLE_LIST'
export const SET_ARTICLE = 'SET_ARTICLE'

export const setArticleList = (articles) => ({
  type: SET_ARTICLE_LIST,
  payload: articles
})
export const setSelectedList = (article) => ({
  type: SET_ARTICLE,
  payload: article
})
